import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { H1, H3 } from '../../styles/TextStyles';
import { spacing } from '../../styles/SpacingStyles';
import { colors } from '../../styles/ColorStyles';
import Container from '../../components/Container';

const CaseContainer = styled.table`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${spacing.xLarge}px;
  margin-bottom: ${spacing.xSmall}px;

  @media (min-width: 768px) {
    margin-top: 0;
    flex-direction: row;
    margin-bottom: ${spacing.medium}px;
  }
`;

const Header = styled(H1)`
  position: absolute;
  top: -22.5%;

  @media (min-width: 768px) {
    top: -40%;
  }

  @media (min-width: 1024px) {
    top: -50%;
  }
`;

const CaseBackground = styled(H3)`
  border: 2px solid ${colors.neutral['neutral-500']};
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.neutral['neutral-500']};
`;

const Copyright = styled.p`
  position: absolute;
  color: ${colors.neutral['neutral-300']};
  bottom: -120%;
  right: 1%;
`;

const Work = () => {
  return (
    <Container>
      <CaseContainer>
        <Header>CASE STUDIES</Header>

        <Link style={{ width: '100%' }} to='/'>
          <CaseBackground>
            <H3 style={{ color: colors.neutral['neutral-100'] }}>
              Coming Soon
            </H3>
          </CaseBackground>
        </Link>

        <Copyright>@2021 JONATHAN HSU</Copyright>
      </CaseContainer>
    </Container>
  );
};

export default Work;
