import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components/macro';
import { H1 } from 'styles/TextStyles';
import { spacing } from '../styles/SpacingStyles';
import schmack from '../assets/play/schmackbox.png';
import glass from '../assets/play/glass.png';
import icons from '../assets/play/macIcons.png';
import plant from '../assets/play/plantTracker.png';
import space from '../assets/play/space.png';
import enigma from '../assets/play/enigma.png';

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.xxxLarge}px 0 ${spacing.xxLarge}px;
`;

const Header = styled(H1)`
  position: absolute;
  top: -4%;

  @media (min-width: 768px) {
    top: -10.5%;
  }

  @media (min-width: 1024px) {
    top: -13%;
  }

  @media (min-width: 1440px) {
    left: -0.4%;
  }
`;

const Media = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 300px 300px;
  grid-template-areas:
    'm1 m2 m2'
    'm1 m3 m4'
    'm5 m5 m6';
  grid-auto-flow: row;
  grid-gap: 16px;
`;

const Img = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const M1 = styled.button`
  grid-area: m1;
  grid-column: 1;
  grid-row: 1 / 2;
`;

const M2 = styled.button`
  grid-area: m2;
  grid-column: 2 / 4;
  grid-row: 1 / 2;
`;

const M3 = styled.button`
  grid-area: m3;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
`;

const M4 = styled.button`
  grid-area: m4;
  grid-column: 3 / 4;
  grid-row: 2 / 3;
`;

const M5 = styled.button`
  grid-area: m5;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
`;

const M6 = styled.button`
  grid-area: m6;
  grid-column: 2 / 4;
  grid-row: 3 / 4;
`;

const LargeImageWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  background: hsl(0, 0%, 9%, 0.3);
`;

const LargeImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30%;
  width: 80%;
  object-fit: contain;

  @media (min-width: 768px) {
    height: 60%;
    width: 80%;
  }

  @media (min-width: 1024px) {
    height: 80%;
    width: 60%;
  }
`;

const DetailedView = ({ image }) => {
  return (
    <LargeImageWrapper>
      <LargeImage src={image} />
    </LargeImageWrapper>
  );
};

const Play = () => {
  const [active, setActive] = useState(false);
  const [image, setImage] = useState('');

  // faster image rendering
  useEffect(() => {
    const imageList = [plant, schmack, icons, glass, space, enigma];

    imageList.forEach((image) => {
      new Image().src = image;
    });
  }, []);

  // object for loading images
  const images = {
    plant: plant,
    schmack: schmack,
    icons: icons,
    space: space,
    enigma: enigma,
    glass: glass,
  };

  // handler for opening detailed image view
  const viewImage = (src) => {
    if (active !== true) {
      setActive(true);
      setImage(images[src]);
    } else {
      setActive(false);
      setImage('');
    }
  };

  return (
    <Layout>
      <MediaWrapper>
        <Media>
          <Header>PLAY</Header>
          <M1 onClick={() => viewImage('plant')}>
            <Img src={plant} />
            {active && <DetailedView image={image} />}
          </M1>
          <M2 onClick={() => viewImage('schmack')}>
            <Img src={schmack} />
            {active && <DetailedView image={image} />}
          </M2>
          <M3 onClick={() => viewImage('space')}>
            <Img src={space} />
            {active && <DetailedView image={image} />}
          </M3>
          <M4 onClick={() => viewImage('icons')}>
            <Img src={icons} />
            {active && <DetailedView image={image} />}
          </M4>
          <M5 onClick={() => viewImage('enigma')}>
            <Img src={enigma} />
            {active && <DetailedView image={image} />}
          </M5>
          <M6 onClick={() => viewImage('glass')}>
            <Img src={glass} />
            {active && <DetailedView image={image} />}
          </M6>
        </Media>
      </MediaWrapper>
    </Layout>
  );
};

export default Play;
