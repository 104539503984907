import styled from 'styled-components/macro';
import { NavHashLink as ReactLink } from 'react-router-hash-link';
import { spacing } from './SpacingStyles';

export const H1 = styled.h1`
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 48px;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 64px;
    line-height: 1;
  }

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 80px;
    line-height: 1;
  }
`;

export const H2 = styled.h2`
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 48px;
    line-height: 1.2;
  }

  @media (min-width: 1024px) {
    font-weight: 400;
    font-size: 64px;
    line-height: 1.2;
  }
`;

export const H3 = styled.h3`
  font-weight: 400;
  font-size: 24px;
  line-height: 1;

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
  }

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 32px;
    line-height: 1px;
  }
`;

export const Logo = styled(ReactLink)`
  font-family: 'Arkipelago';
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  padding-bottom: ${spacing.small}px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Link = styled(ReactLink)`
  font-weight: 400;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`;

export const Title = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
`;

export const BodyText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
`;
