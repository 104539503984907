import React from 'react';
import styled from 'styled-components/macro';
import { spacing } from '../../styles/SpacingStyles';

const Svg = styled.svg`
  margin-right: ${spacing.medium}px;
  padding: 0 0 ${spacing.medium}px;

  @media (min-width: 768px) {
    margin-right: 0;
  }
`;

const PenIcon = ({ active }) => {
  return (
    <Svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.9675 8.16246H9.76956C9.11459 8.16227 8.47543 8.35754 7.93886 8.72176C7.40229 9.08598 6.99431 9.6015 6.77031 10.1983L0.771809 26.1974C0.559628 26.7621 0.704805 27.3948 1.14193 27.8202L1.60937 28.2735L11.8547 18.3386C11.8531 18.2984 11.8419 18.2582 11.8419 18.218C11.8419 17.759 11.9823 17.3104 12.2452 16.9288C12.5082 16.5472 12.8819 16.2497 13.3192 16.0741C13.7564 15.8985 14.2376 15.8525 14.7018 15.9421C15.166 16.0316 15.5924 16.2526 15.9271 16.5771C16.2617 16.9017 16.4896 17.3151 16.582 17.7653C16.6743 18.2154 16.6269 18.682 16.4458 19.106C16.2647 19.53 15.958 19.8924 15.5644 20.1474C15.1709 20.4024 14.7082 20.5385 14.2349 20.5385C14.1935 20.5385 14.152 20.5277 14.1105 20.5261L3.86518 30.461L4.33262 30.9142C4.54712 31.1227 4.8187 31.2674 5.11526 31.331C5.41182 31.3946 5.72097 31.3745 6.00614 31.2731L22.5052 25.4549C23.1207 25.2376 23.6523 24.842 24.0279 24.3217C24.4035 23.8014 24.6049 23.1816 24.6047 22.5465V16.5364L27.7938 13.4455L19.1566 5.07001L15.9675 8.16246V8.16246Z'
        fill='black'
        opacity={active ? 1 : 0.5}
      />
      <path
        d='M29.2934 11.3524L21.3167 3.61737L24.6988 0.336182L32.6755 8.07119L29.2934 11.3524Z'
        fill='black'
        opacity={active ? 1 : 0.5}
      />
    </Svg>
  );
};

export default PenIcon;
