import React from 'react';
import { H1 } from '../styles/TextStyles';
import Container from '../components/Container';
import styled from 'styled-components/macro';

const Spacer = styled.div`
  padding-left: 32px;
`;

const Hidden = () => {
  return (
    <Container>
      <Spacer>
        <H1>
          🚧{'  '}WORK IN PROGRESS...{'  '}🚧
        </H1>
      </Spacer>
    </Container>
  );
};

export default Hidden;
