// @ts-nocheck
import React, { useState, useEffect } from 'react';
import useWindowSize from '../utils/hooks/useWindowSize';
import styled from 'styled-components/macro';
import { Link, Logo } from '../styles/TextStyles';
import { spacing } from '../styles/SpacingStyles';
import { colors } from '../styles/ColorStyles';
import HamburgerMenu from './Icons/HamburgerMenu';
import CloseIcon from './Icons/CloseIcon';

const Wrapper = styled.div`
  grid-area: nav;
  position: fixed;
  top: 50%;
  left: 10%;
  height: 250px;
  margin-top: -125px;
`;

const MenuLogo = styled(Logo)`
  padding-left: ${spacing.large}px;
  color: ${colors.neutral['neutral-100']};

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    a:visited {
      color: ${colors.neutral['neutral-100']};
    }
  }

  @media (min-width: 768px) {
    padding-left: ${spacing.small}px;
    color: ${colors.neutral['neutral-500']};
  }
`;

const MenuIcon = styled.button`
  position: fixed;
  left: ${spacing.small}px;
  bottom: ${spacing.small}px;

  @media (min-width: 768px) {
    left: ${spacing.xLarge}px;
    bottom: ${spacing.xLarge}px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const NavMenu = styled.nav``;

const MobileNavMenu = styled.nav`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: ${colors.neutral['neutral-500']};
  padding-left: ${spacing.small}px;

  @media (min-width: 768px) {
    padding-left: ${spacing.xLarge}px;
  }

  &:visited {
    color: ${colors.neutral['neutral-100']};
  }
`;

const NavList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const MobileNavList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  position: fixed;
  top: 40%;

  @media (min-width: 1024px) {
    align-items: flex-end;
  }
`;

const MenuLink = styled(Link)`
  color: ${(props) =>
    props.size === 'small'
      ? colors.neutral['neutral-100']
      : colors.neutral['neutral-500']};
  margin: ${spacing.small}px 0;
`;

const Nav = () => {
  const [mobile, setMobile] = useState(false);
  const [open, setOpen] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    if (size.width <= 1023) {
      setMobile(true);
    } else {
      setOpen(false);
      setMobile(false);
    }
  }, [size]);

  return (
    <Wrapper>
      {mobile && (
        <MenuIcon onClick={() => setOpen(!open)}>
          <HamburgerMenu />
        </MenuIcon>
      )}

      {!mobile && (
        <NavMenu>
          <NavList>
            <MenuLogo smooth size='large' to='/#intro'>
              jh
            </MenuLogo>
            <MenuLink smooth size='large' to='/#work'>
              WORK
            </MenuLink>
            <MenuLink size='large' to='/play'>
              PLAY
            </MenuLink>
            <MenuLink size='large' to='/about'>
              ABOUT
            </MenuLink>
          </NavList>
        </NavMenu>
      )}

      {open && (
        <MobileNavMenu>
          <MenuIcon
            onClick={() => {
              setOpen(!open);
            }}
          >
            <CloseIcon />
          </MenuIcon>

          <MobileNavList>
            <MenuLogo
              smooth
              size='small'
              to='/#intro'
              onClick={() => setOpen(!open)}
            >
              jh
            </MenuLogo>
            <MenuLink
              smooth
              size='small'
              to='/#work'
              onClick={() => setOpen(!open)}
            >
              WORK
            </MenuLink>
            <MenuLink size='small' to='/play' onClick={() => setOpen(!open)}>
              PLAY
            </MenuLink>
            <MenuLink size='small' to='/about' onClick={() => setOpen(!open)}>
              ABOUT
            </MenuLink>
          </MobileNavList>
        </MobileNavMenu>
      )}
    </Wrapper>
  );
};

export default Nav;
