import React from 'react';
import styled from 'styled-components/macro';
import { spacing } from '../styles/SpacingStyles';
import Nav from './Nav';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'nav main';
  }

  @media (min-width: 1024px) {
    grid-template-columns: 2fr 6fr;
    grid-template-areas: 'nav main';
  }

  @media (min-width: 1440px) {
    grid-template-columns: 2fr 10fr;
    grid-template-areas: 'nav main';
  }
`;

const GridItem = styled.div`
  grid-area: main;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 ${spacing.small}px;

  @media (min-width: 768px) {
    padding: 0 ${spacing.xLarge}px;
  }

  @media (min-width: 1024px) {
    padding: 0 ${spacing.xLarge}px;
  }

  @media (min-width: 1440px) {
    padding: 0 ${spacing.xxxLarge}px;
  }
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Grid>
        <GridItem>{children}</GridItem>
        <Nav />
      </Grid>
    </Wrapper>
  );
};

export default Layout;
