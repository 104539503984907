import React, { useState } from 'react';
import { Title } from 'styles/TextStyles';
import Layout from '../../components/Layout';
import PenIcon from 'components/Icons/PenIcon';
import soestern from '../../assets/banners/soestern.svg';

import {
  Wrapper,
  CoverImgWrapper,
  ProjectName,
  CoverImg,
  WorkWrapper,
  WorkInfo,
  InfoSection,
  Label,
  ContentWrapper,
  Content,
  ContentSection,
  Headline,
  Body,
  Img,
  Toggle,
  ToggleButton,
  DesignLabel,
} from 'components/Work';

const Soestern = () => {
  const [design, setDesign] = useState(true);

  const toggleDesign = () => {
    if (!design) {
      setDesign(true);
    }
  };

  return (
    <Layout>
      <Wrapper>
        <CoverImgWrapper>
          <ProjectName>Soestern</ProjectName>
          <CoverImg src={soestern} />
        </CoverImgWrapper>

        <WorkWrapper>
          <WorkInfo>
            <InfoSection>
              <Label>ROLE</Label>
              <Title>UI Designer</Title>
            </InfoSection>

            <InfoSection>
              <Label>LENGTH</Label>
              <Title>2 Months</Title>
            </InfoSection>

            <InfoSection>
              <Label>FOCUS</Label>
              <Title>
                UI Design, UX Research, Design Systems, Style Guide, Figma
              </Title>
            </InfoSection>
          </WorkInfo>

          <ContentWrapper>
            <Content>
              <ContentSection>
                <Headline>Introduction</Headline>
                <Body>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>Process</Headline>
                <Body>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </Body>

                <Img />

                <Body>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>Results</Headline>
                <Body>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </Body>

                <Img />

                <Body>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </Body>
              </ContentSection>
            </Content>

            <Toggle>
              <ToggleButton onClick={toggleDesign}>
                <PenIcon active={design} />
                <DesignLabel>DESIGN</DesignLabel>
              </ToggleButton>
            </Toggle>
          </ContentWrapper>
        </WorkWrapper>
      </Wrapper>
    </Layout>
  );
};
export default Soestern;
