import { createGlobalStyle } from 'styled-components';
import { colors } from './ColorStyles';

// setting color based on theme preferences (light mode | dark mode)

export const theme = createGlobalStyle`
  body {
    background: ${colors.backgroundColor};

    @media (prefers-color-scheme: dark) {
      background: ${colors['neutral-500']};
    }
  }
`;
