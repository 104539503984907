import React from 'react';
import styled from 'styled-components/macro';
import { spacing } from '../../styles/SpacingStyles';

const Svg = styled.svg`
  margin-right: ${spacing.medium}px;
  padding: 0 0 ${spacing.medium}px;

  @media (min-width: 768px) {
    margin-right: 0;
  }
`;

const CodeIcon = ({ active }) => {
  return (
    <Svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M9.10035 23.7493L11.1003 21.12L4.66215 15.7014L11.1003 10.2828L9.10035 7.65341L1.10072 14.3867C0.913284 14.5444 0.761967 14.7444 0.65798 14.972C0.553993 15.1996 0.5 15.4489 0.5 15.7014C0.5 15.9539 0.553993 16.2031 0.65798 16.4307C0.761967 16.6583 0.913284 16.8583 1.10072 17.016L9.10035 23.7493ZM23.8997 7.65341L21.8997 10.2828L28.3378 15.7014L21.8997 21.12L23.8997 23.7493L31.8993 17.016C32.0867 16.8583 32.238 16.6583 32.342 16.4307C32.446 16.2031 32.5 15.9539 32.5 15.7014C32.5 15.4489 32.446 15.1996 32.342 14.972C32.238 14.7444 32.0867 14.5444 31.8993 14.3867L23.8997 7.65341Z'
          fill='black'
          opacity={active ? 1 : 0.5}
        />
        <path
          d='M21.2614 0.91507L14.8617 31.2148L11.7371 30.4843L18.1368 0.184509L21.2614 0.91507Z'
          fill='black'
          opacity={active ? 1 : 0.5}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect
            width='32'
            height='32'
            fill='white'
            transform='translate(0.5)'
            opacity={active ? 1 : 0.5}
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default CodeIcon;
