const HamburgerMenu = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='8' fill='#222222' />
      <rect y='16' width='40' height='8' fill='#222222' />
      <rect y='32' width='20' height='8' fill='#222222' />
    </svg>
  );
};

export default HamburgerMenu;
