import React, { useState, useEffect } from 'react';
import useWindowSize from '../utils/hooks/useWindowSize';
import { H1, H2 } from '../styles/TextStyles';
import styled from 'styled-components/macro';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Work from './works/Work';
import CaseStudies from './caseStudies/CaseStudies';

const IntroText = styled(H2)`
  text-transform: uppercase;
`;

const Home = () => {
  const [mobile, setMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width <= 1023) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [size]);

  return (
    <>
      <Layout>
        <div id='intro'>
          <Container>
            {!mobile ? <H1>JONATHAN HSU</H1> : <H1>JON HSU</H1>}
            <IntroText>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint.
            </IntroText>
          </Container>
        </div>

        <div id='work'>
          <Container>
            <Work />
          </Container>
        </div>

        <Container>
          <CaseStudies />
        </Container>
      </Layout>
    </>
  );
};

export default Home;
