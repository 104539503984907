const CloseIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.00071255 33.3333L33.3335 5.61958e-07L40 6.66667L6.66726 40L0.00071255 33.3333Z'
        fill='white'
      />
      <path
        d='M6.66655 0L39.9993 33.3333L33.3327 40L0 6.66667L6.66655 0Z'
        fill='white'
      />
    </svg>
  );
};

export default CloseIcon;
