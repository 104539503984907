import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { H1, H2, H3 } from '../../styles/TextStyles';
import { spacing } from '../../styles/SpacingStyles';
import { colors } from '../../styles/ColorStyles';
import Container from '../../components/Container';
import cyral from '../../assets/thumbnails/cyral.svg';
import apollo from '../../assets/thumbnails/apollo.svg';
import soestern from '../../assets/thumbnails/soestern.svg';

const WorkContainer = styled.table`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${spacing.xLarge}px;
  margin-bottom: ${spacing.xSmall}px;

  @media (min-width: 768px) {
    margin-top: 0;
    flex-direction: row;
    margin-bottom: ${spacing.medium}px;
  }
`;

const Header = styled(H1)`
  position: absolute;
  top: -22.5%;

  @media (min-width: 768px) {
    top: -40%;
  }

  @media (min-width: 1024px) {
    top: -50%;
  }
`;

const Year = styled(H2)`
  border: 2px solid black;
  min-width: 240px;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProjectList = styled.table`
  border-collapse: collapse;
  border-style: solid;
  border-color: ${colors.neutral['neutral-500']};
  border-width: 0 0 0 2px;
  width: 100%;

  @media (min-width: 768px) {
    border-width: 2px 0 0 0;
    max-width: 1813.33px;
  }
`;

const Project = styled.tr`
  height: 80px;
  max-height: 100%;
  display: flex;
  align-items: center;
  border-style: solid;
  border-color: ${colors.neutral['neutral-500']};
  border-width: 0px 2px 2px 0;
`;

const Img = styled.img`
  width: 78px;
  height: 78px;
  border-right: 2px solid ${colors.neutral['neutral-500']};
`;

const Title = styled(H3)`
  padding-left: ${spacing.small}px;
`;

const Work = () => {
  return (
    <Container>
      <WorkContainer>
        <Header>WORK</Header>
        <Year>2021</Year>

        <ProjectList>
          <Link to='/work/cyral'>
            <Project>
              <Img src={cyral} />
              <Title>Cyral</Title>
            </Project>
          </Link>
        </ProjectList>
      </WorkContainer>

      <WorkContainer>
        <Year>2020</Year>

        <ProjectList>
          <Link to='/work/soestern'>
            <Project>
              <Img src={soestern} />
              <Title>Soestern</Title>
            </Project>
          </Link>
          <Link to='/work/apollo'>
            <Project>
              <Img src={apollo} />
              <Title>Apollo</Title>
            </Project>
          </Link>
        </ProjectList>
      </WorkContainer>
    </Container>
  );
};

export default Work;
