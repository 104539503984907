import styled from 'styled-components/macro';
import { H1, H2, Title, Subtitle, BodyText } from '../styles/TextStyles';
import { spacing } from '../styles/SpacingStyles';
import { colors } from '../styles/ColorStyles';

export const Wrapper = styled.div`
  padding: ${spacing.xxxLarge}px 0 ${spacing.medium}px;
  margin-bottom: ${spacing.xLarge}px;
`;

export const ProjectName = styled(H1)`
  position: absolute;
  top: -22.5%;

  @media (min-width: 768px) {
    top: -40%;
  }

  @media (min-width: 1024px) {
    top: -50%;
  }

  @media (min-width: 1440px) {
    left: -0.4%;
  }
`;

export const CoverImgWrapper = styled.div`
  position: relative;
`;

export const CoverImg = styled.img`
  width: 100%;
  height: 160px;
  background: ${colors.neutral['neutral-100']};
  object-fit: contain;
  border-top: 4px solid ${colors.neutral['neutral-500']};
  border-bottom: 4px solid ${colors.neutral['neutral-500']};

  @media (min-width: 768px) {
    height: 240px;
  }
`;

export const WorkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: ${spacing.large}px;
  }
`;

export const WorkInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${spacing.small}px 0;

  @media (min-width: 1024px) {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 0;
    width: 20%;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.medium}px;
  padding-bottom: ${spacing.medium}px;

  @media (min-width: 768px) {
    padding-right: none;
  }
`;

export const Label = styled(Subtitle)`
  color: ${colors.neutral['neutral-400']};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 1024px) {
    width: 80%;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 75%;
    align-items: flex-start;
  }

  @media (min-width: 1024px) {
    padding-left: ${spacing.large}px;
  }

  @media (min-width: 1440px) {
    padding: 0 ${spacing.large}px 0 ${spacing.xLarge}px;
  }

  @media (min-width: 1441px) {
    padding: 0 ${spacing.xxLarge}px;
  }
`;

export const ContentSection = styled.div`
  padding-bottom: ${spacing.medium}px;
`;

export const Headline = styled(Title)`
  padding-bottom: ${spacing.xSmall}px;
  display: inline-block;
  font-weight: 500;
`;

export const Body = styled(BodyText)`
  padding-bottom: ${spacing.small}px;
  line-height: 2;
`;

export const Img = styled.img`
  width: 100%;
  height: 160px;
  background: ${colors.neutral['neutral-500']};
  margin-bottom: ${spacing.small}px;
  object-fit: cover;

  @media (min-width: 768px) {
    height: 240px;
  }
`;

export const Toggle = styled.div`
  display: flex;
  margin: ${spacing.medium}px 0;

  @media (min-width: 768px) {
    width: 25%;
    margin: 0;
    flex-direction: column;
    align-items: center;
    padding-left: ${spacing.xxLarge}px;
  }

  @media (min-width: 1024px) {
    padding-left: ${spacing.medium}px;
  }

  @media (min-width: 1440px) {
    padding-left: 0;
  }
`;

export const ToggleButton = styled.button`
  position: relative;
`;

export const DesignLabel = styled(H2)`
  color: ${colors.neutral['neutral-500']};
  opacity: 0.5;
  position: absolute;
  top: -5%;
  right: -200%;

  @media (min-width: 768px) {
    top: 200%;
    right: -190%;
    transform: rotate(90deg);
  }

  @media (min-width: 1024px) {
    top: 250%;
    right: -280%;
  }

  @media (min-width: 1440px) {
    top: 250%;
    right: -290%;
  }
`;

export const CodeLabel = styled(H2)`
  color: ${colors.neutral['neutral-500']};
  opacity: 0.5;
  position: absolute;
  top: -5%;
  right: -200%;

  @media (min-width: 768px) {
    top: 200%;
    right: -120%;
    transform: rotate(90deg);
  }

  @media (min-width: 1024px) {
    top: 250%;
    right: -190%;
  }

  @media (min-width: 1440px) {
    top: 250%;
    right: -200%;
  }
`;

export const Link = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

export const List = styled.ol``;

export const ListItem = styled.li`
  margin: ${spacing.tiny}px 0 0;
  padding-left: ${spacing.medium}px;

  @media (max-width: 375px) {
    padding-left: 0;
  }
`;

export const Bold = styled.span`
  font-weight: 700;
`;
