export const colors = {
  background: 'hsl(0, 0%, 100%)',
  neutral: {
    'neutral-100': 'hsl(0, 0%, 100%)',
    'neutral-200': 'hsl(0, 0%, 81%)',
    'neutral-300': 'hsl(0, 0%, 62%)',
    'neutral-400': 'hsl(0, 0%, 38%)',
    'neutral-500': 'hsl(0, 0%, 9%)',
  },
  accent: {
    // blue
    'blue-000': 'hsl(195,100%,95%)',
    'blue-100': 'hsl(195,100%,85%)',
    'blue-200': 'hsl(195,97%,75%)',
    'blue-300': 'hsl(196,94%,67%)',
    'blue-400': 'hsl(197,92%,61%)',
    'blue-500': 'hsl(199,84%,55%)',
    'blue-600': 'hsl(201,79%,46%)',
    'blue-700': 'hsl(202,83%,41%)',
    'blue-800': 'hsl(203,87%,34%)',
    'blue-900': 'hsl(204,96%,27%)',
    // green
    'green-000': 'hsl(152,68%,96%)',
    'green-100': 'hsl(154,75%,87%)',
    'green-200': 'hsl(156,73%,74%)',
    'green-300': 'hsl(158,58%,62%)',
    'green-400': 'hsl(160,51%,49%)',
    'green-500': 'hsl(162,63%,41%)',
    'green-600': 'hsl(164,71%,34%)',
    'green-700': 'hsl(166,72%,28%)',
    'green-800': 'hsl(168,80%,23%)',
    'green-900': 'hsl(170,97%,15%)',
    // red
    'red-000': 'hsl(0,100%,95%)',
    'red-100': 'hsl(0,100%,87%)',
    'red-200': 'hsl(0,100%,80%)',
    'red-300': 'hsl(0,91%,69%)',
    'red-400': 'hsl(0,83%,62%)',
    'red-500': 'hsl(356,75%,53%)',
    'red-600': 'hsl(354,85%,44%)',
    'red-700': 'hsl(352,90%,35%)',
    'red-800': 'hsl(350,94%,28%)',
    'red-900': 'hsl(348,94%,20%)',
    // yellow
    'yellow-000': 'hsl(49,100%,96%)',
    'yellow-100': 'hsl(48,100%,88%)',
    'yellow-200': 'hsl(48,95%,76%)',
    'yellow-300': 'hsl(48,94%,68%)',
    'yellow-400': 'hsl(44,92%,63%)',
    'yellow-500': 'hsl(42,87%,55%)',
    'yellow-600': 'hsl(36,77%,49%)',
    'yellow-700': 'hsl(29,80%,44%)',
    'yellow-800': 'hsl(22,82%,39%)',
    'yellow-900': 'hsl(15,86%,30%)',
  },
};
