import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components/macro';
import { Title } from '../styles/TextStyles';
import { spacing } from '../styles/SpacingStyles';
import { colors } from '../styles/ColorStyles';
import sb from '../assets/banners/sb.jpg';
// import resume from '../assets/Jonathan_Hsu_Resume.pdf';

import {
  Wrapper,
  CoverImgWrapper,
  ProjectName,
  CoverImg,
  WorkWrapper,
  WorkInfo,
  InfoSection,
  Label,
  Content,
  ContentSection,
  Headline,
  Body,
} from 'components/Work';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 1024px) {
    width: 80%;
    justify-content: space-between;
  }
`;

const Socials = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: flex-start;
  margin-bottom: ${spacing.xLarge}px;
`;

const Link = styled.a`
  font-weight: 400;
  font-size: 28px;
  line-height: 1;
  text-transform: uppercase;
  padding: ${spacing.xSmall}px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Email = styled.a`
  color: ${colors.accent['red-400']};

  &:hover {
    color: ${colors.accent['red-400']};
  }
`;

const About = () => {
  return (
    <Layout>
      <Wrapper>
        <CoverImgWrapper>
          <ProjectName>ABOUT</ProjectName>
          <CoverImg src={sb} style={{ objectFit: 'cover' }} />
        </CoverImgWrapper>
        <WorkWrapper>
          <WorkInfo>
            <InfoSection>
              <Label>ROLES</Label>
              <Title>UI Designer, Front-End Developer</Title>
            </InfoSection>

            <InfoSection>
              <Label>LOCATION</Label>
              <Title>Los Angeles, CA</Title>
            </InfoSection>

            <InfoSection>
              <Label>INTERESTS</Label>
              <Title>UI, UX, Figma, Code, Tennis, Sci-Fi Books</Title>
            </InfoSection>
          </WorkInfo>

          <ContentWrapper>
            <Content>
              <ContentSection>
                <Headline>Hey! I'm Jonathan</Headline>
                <Body style={{ paddingBottom: '16px' }}>
                  I'm a UI designer based in southern California. My love for
                  design stemmed from early exposure to Adobe Photoshop back
                  when I was in middle school, but I hadn't considered pursuing
                  a career in this field until recently.
                </Body>

                <Body style={{ paddingBottom: '16px' }}>
                  I graduated from college back in 2019 and transitioned into
                  tech at the start of 2020, through a bootcamp where I studied
                  full-stack web development. During my bootcamp experience, I
                  studied UI design on my own, building up my experience through
                  various contract roles.
                </Body>

                <Body style={{ paddingBottom: '16px' }}>
                  My non-traditional journey breaking into tech has helped me
                  develop diverse perspectives when solving problems. Not only
                  has my full-stack development experience enhanced my design
                  capabilities, it has also improved how I, as a designer,
                  collaborate with teams of developers.
                </Body>

                <Body>
                  Feel free to reach out to me at{' '}
                  <Email>jonhsu97@gmail.com</Email>!
                </Body>
              </ContentSection>
            </Content>

            <Socials>
              {/* <Link href={resume} target='_blank' rel='noopener noreferrer'>
                Resume
              </Link> */}
              <Link
                href='https://www.linkedin.com/in/jon-hsu/'
                target='_blank'
                rel='noopener noreferrer'
              >
                LinkedIn
              </Link>
              <Link
                href='https://github.com/jonush'
                target='_blank'
                rel='noopener noreferrer'
              >
                GitHub
              </Link>
              <Link
                href='https://twitter.com/nojhsu'
                target='_blank'
                rel='noopener noreferrer'
              >
                Twitter
              </Link>
              <Link
                href='https://open.spotify.com/playlist/1N9aXI52crNvjrzN9EwT10?si=d12972bd144d45a5'
                target='_blank'
                rel='noopener noreferrer'
              >
                Spotify
              </Link>
            </Socials>
          </ContentWrapper>
        </WorkWrapper>
      </Wrapper>
    </Layout>
  );
};
export default About;
