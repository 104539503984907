import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/Theme';

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'normalize.css';
import ProgressBar from 'react-scroll-progress-bar';
import AnimatedCursor from 'react-animated-cursor';

import Hidden from './pages/Hidden';
import Home from './pages/Home';
import Cyral from './pages/works/Cyral';
import Case from './pages/caseStudies/Case';
import Play from './pages/Play';
import About from 'pages/About';
import Apollo from 'pages/works/Apollo';
import Soestern from 'pages/works/Soestern';

const App = () => {
  const [home, setHome] = useState(false);

  useEffect(() => {
    AOS.init();
  });

  return (
    <ThemeProvider theme={theme}>
      <ProgressBar bgcolor='#000000' />

      <AnimatedCursor
        color='0, 98, 255'
        innerSize={8}
        outerSize={24}
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={3}
        trailingSpeed={1}
      />

      <Switch>
        {/* hide the site when pushing changes */}
        {home ? (
          <Route exact path='/' component={Home} />
        ) : (
          <Route exact path='/' component={Hidden} />
        )}

        <Route path='/work/cyral' component={Cyral} />
        <Route path='/work/apollo' component={Apollo} />
        <Route path='/work/soestern' component={Soestern} />
        <Route path='/case' component={Case} />
        <Route path='/play' component={Play} />
        <Route path='/about' component={About} />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
