export const spacing = {
  tiny: 4,
  xSmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  xLarge: 40,
  xxLarge: 80,
  xxxLarge: 160,
};
