import React, { useState } from 'react';
import { Title } from '../../styles/TextStyles';
import Layout from '../../components/Layout';
import PenIcon from '../../components/Icons/PenIcon';
import cyral from '../../assets/banners/cyral.svg';
import Notes from '../../assets/cyral/notes.png';
import Typography from '../../assets/cyral/typography.png';
import Buttons from '../../assets/cyral/buttons.png';

import {
  Wrapper,
  CoverImgWrapper,
  ProjectName,
  CoverImg,
  WorkWrapper,
  WorkInfo,
  InfoSection,
  Label,
  ContentWrapper,
  Content,
  ContentSection,
  Headline,
  Body,
  Img,
  Toggle,
  ToggleButton,
  DesignLabel,
  Link,
  List,
  ListItem,
  Bold,
} from 'components/Work';

const Cyral = () => {
  const [design, setDesign] = useState(true);

  const toggleDesign = () => {
    if (!design) {
      setDesign(true);
    }
  };

  return (
    <Layout>
      <Wrapper>
        <CoverImgWrapper>
          <ProjectName>Cyral</ProjectName>
          <CoverImg src={cyral} />
        </CoverImgWrapper>

        <WorkWrapper>
          <WorkInfo>
            <InfoSection>
              <Label>ROLE</Label>
              <Title>UI Designer</Title>
            </InfoSection>

            <InfoSection>
              <Label>LENGTH</Label>
              <Title>1 Month</Title>
            </InfoSection>

            <InfoSection>
              <Label>FOCUS</Label>
              <Title>
                UI Design, UX Research, Design Systems, Style Guide, Figma
              </Title>
            </InfoSection>
          </WorkInfo>

          <ContentWrapper>
            <Content>
              <ContentSection>
                <Headline>Intro</Headline>
                <Body>
                  <Bold>Problem</Bold>:{' '}
                  <Link
                    href='https://cyral.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: '#26459E' }}
                  >
                    Cyral
                  </Link>{' '}
                  is a startup focused on improving security for data stored in
                  cloud repositories. The addition of new features has led to
                  design inconsistencies throughout the application.
                </Body>

                <Body>
                  <Bold>Solution</Bold>: A design style guide for developers to
                  reference.
                </Body>

                <Body>
                  <Bold>Impact</Bold>: I delivered a completed style guide with
                  proper documentation. In the process, I also addressed all
                  existing inconsistencies, working with project managers and
                  the front-end developers to eliminate them.
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>Define Project Details</Headline>
                <Body>
                  The primary goal for this contract was to produce a style
                  guide for developers to reference as they continue building
                  out Cyral's product. The main goals for creating a style guide
                  were:
                </Body>

                <Body>
                  <List>
                    <ListItem>
                      1. To eliminate inconsistencies currently existing
                      throughout the application.
                    </ListItem>
                    <ListItem>
                      2. Define, update, and document the current design styles
                      to standardize them for future reference.
                    </ListItem>
                  </List>
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>UX Challenges</Headline>

                <Body>
                  The biggest initial challenge with creating this style guide
                  was documenting where the UI inconsistencies currently existed
                  in the application. Coming into this challenge as a contract
                  designer, I was unfamiliar with the existing product. I needed
                  time to familiarize myself with the application and take into
                  account all the various factors (business goals, technical
                  limitations, design decisions) that led to the inconsistencies
                  in the first place before I was able to even think about how I
                  might solve them.
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>Research + Planning</Headline>
                <Body>
                  I spent some time with the product manager to walk through the
                  application, focusing on when, where, and how certain UI
                  elements are used. These were the questions I focused on when
                  familiarizing myself with the product:
                </Body>

                <Body>
                  <List>
                    <ListItem>• What context is this element used?</ListItem>
                    <ListItem>
                      • Are there any business goals or technical limitations
                      that led to this design choice?
                    </ListItem>
                    <ListItem>
                      • Are any of the existing design styles critical to the
                      core UX of this application?
                    </ListItem>
                  </List>
                </Body>

                <Body>
                  With regards to structuring the style guide deliverables, I
                  defined sections for the core styles:{' '}
                  <Bold>
                    typography, color, layout, spacing, elevation, border
                    radius, and iconography.
                  </Bold>{' '}
                  For this particular contract, standardized styles for{' '}
                  <Bold>buttons and selectors</Bold> were requested as well.
                </Body>

                <Img src={Notes} />

                <Body>
                  After I established an understanding of the app, I compiled a
                  list of elements where there were styling inconsistencies and
                  addressed those with the PM and front-end devs to figure out
                  which styles to standardize for the UI. I identified an
                  opportunity here to incorporate modern design styles into the
                  existing application.
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>Solution</Headline>
                <Body>
                  To address inconsistencies, I documented use cases and
                  contexts for each type of UI element as well as where they
                  split into distinct variations. After figuring out where all
                  the inconsistencies were, I worked with the product manager
                  and developers to decide which variations of each element to
                  standardize in the style guide—in some cases, it was agreed
                  that none of the existing variations should be used, and the
                  element should be redesigned.
                </Body>

                <Img src={Typography} />

                <Body>
                  I selected each style in the style guide by determining if any
                  given style already existed and only needed to be documented
                  or if it was a newly defined style. I tested the newly
                  selected styles on multiple screen sizes by manipulating the
                  CSS code of the application. I placed a heavy focus on
                  establishing consistency throughout the app while maintaining
                  the look and feel of the Cyral brand.
                </Body>

                <Body>
                  When documenting style usage and CSS properties, I worked
                  closely with the front-end developers to produce a guide they
                  could reference efficiently. As I've had plenty of experience
                  as a developer working with a designers and design
                  deliverables, I know that a lot of complications in the
                  designer{' '}
                  <span style={{ fontFamily: 'Fira Code' }}>{`<->`}</span>{' '}
                  developer process can be avoided with consistent communication
                  and detailed documentation.
                </Body>

                <Body>
                  Although I don't fully agree with the approach of a designer
                  to developer "hand-off", the concept was especially true in
                  this case as I was a contract designer. With that being the
                  case, it was especially important for me that I produce a
                  style guide that was easy for the Cyral developers to use. For
                  example, I documented style usage in the context of Cyral's
                  product and defined all measurements and design tokens to be
                  consistent with the values that the developers were already
                  using in their code.
                </Body>

                <Img src={Buttons} />

                <Body>
                  With business priorities and user needs constantly changing, I
                  prioritized for styling changes that were low-effort to
                  implement, but could offer substantial visual improvements to
                  the UI. I suggested some of the larger UI changes to the team,
                  but left those as things to think about later, since:
                </Body>

                <Body>
                  <List>
                    <ListItem>
                      1. Some changes were more high-effort, high-impact
                      improvements
                    </ListItem>
                    <ListItem>
                      2. The suggestions were centered around UI component
                      redesigns, which were out of scope for this contract.
                    </ListItem>
                  </List>
                </Body>

                <Body style={{ fontStyle: 'italic' }}>
                  Due to the scope of the contract, I was not able to conduct
                  any user testing for elements created with the newly defined
                  styles. The length of the contract and the timeline for
                  implementing the new styles did not line up.
                </Body>
              </ContentSection>

              <ContentSection>
                <Headline>Outcomes</Headline>

                <Body>
                  Working on a style guide for long-term usage allowed me to
                  develop a better understanding of how standardized styles can
                  help cross-functional teams build better products. I learned
                  about the significance of micro UI design choices and how they
                  can extend throughout a product to establish visual
                  consistency.
                </Body>

                <Body>
                  My next steps would have been to build off the delivered
                  styles for the components I referenced, and apply that process
                  to all the other UI components in a design system.{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    However, that was not in the scope of this current project
                    and was just a potential opportunity moving forward.
                  </span>
                </Body>

                <Body>
                  As a short-term contract hire, I lacked some context that
                  might have helped me understand how the design processes at
                  Cyral could have led the the current state of their product's
                  UI—I felt that this would help me create a style guide that
                  would address the design issues they had and make it easier
                  for their team to build out their UI elements moving forward.
                  As such, I learned how to ask better questions to quickly gain
                  context relevant to the problem I needed to solve.
                </Body>

                <Body>
                  <span style={{ fontStyle: 'italic' }}>
                    Please reach out to me toview the style guide.
                  </span>
                </Body>
              </ContentSection>
            </Content>

            <Toggle>
              <ToggleButton onClick={toggleDesign}>
                <PenIcon active={design} />
                <DesignLabel>DESIGN</DesignLabel>
              </ToggleButton>
            </Toggle>
          </ContentWrapper>
        </WorkWrapper>
      </Wrapper>
    </Layout>
  );
};
export default Cyral;
